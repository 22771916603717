<template>
  <section class="audience-selector">
    <div class="import-selector" v-if="!showBotSelector">
      <div
        class="import-selector__option con-vs-card withHover"
        :class="{ active: active === 1 }"
        @click="importFromExcel()"
      >
        <div class="body">
          <vs-icon
            style
            color="warning"
            icon="icon-file-plus"
            iconPack="feather"
          />
        </div>
        <div class="text">
          <span class="text__small">
            {{
              lang.audiences.add.wizard.audienceSelector.from[languageSelected]
            }}
          </span>
          <p class="text__big">Excel</p>
        </div>
        <input
          id="excel-input"
          type="file"
          @change="excelFileChange"
          ref="excelFile"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      </div>
      <div
        class="import-selector__option con-vs-card withHover"
        :class="{ active: active === 2 }"
        @click="importFromBot()"
      >
        <div class="body">
          <vs-icon style color="warning" icon="icon-box" iconPack="feather" />
        </div>
        <div class="text">
          <span class="text__small">
            {{
              lang.audiences.add.wizard.audienceSelector.from[languageSelected]
            }}
          </span>
          <p class="text__big">Bot</p>
        </div>
      </div>
    </div>
    <vs-button
      v-if="!showBotSelector"
      color="primary"
      type="flat"
      @click="downloadExcelTemplate"
      class="download-button"
    >
      {{
        lang.audiences.add.wizard.audienceSelector.downloadTemplate[
          languageSelected
        ]
      }}
    </vs-button>

    <div v-if="showBotSelector">
      <!-- <BotSelector :bots="getBots" v-if="getBots" @onSelect="selectBot" /> -->
      <vs-col
        v-if="getBots"
        :key="index"
        v-for="(bot, index) in getBots"
        vs-type="flex"
        vs-justify="center"
        vs-align="stretch"
        vs-xs="12"
        vs-sm="6"
        vs-lg="6"
        class="all-bots-view"
      >
        <BotItem :bot="bot" :show-actions="false" :select-bot="selectBot" />
      </vs-col>

      <vs-button
        type="flat"
        class="float-right mt-2"
        icon-pack="feather"
        icon="icon-x"
        color="danger"
        @click="cancel"
      >
        {{ lang.apiCalls.edit.cancelText[languageSelected] }}
      </vs-button>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import xlsx from '../../../entities/xlsx'

export default {
  name: 'AudienceImporter',
  components: {
    // BotSelector: () => import('./BotSelector.vue'),
    BotItem: () => import('../../../bot-maker/bots-view/bots/BotItem.vue')
  },
  data() {
    return {
      active: null,
      showBotSelector: false,
      newValues: [],
      selected: []
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('bots', ['getBots']),
    ...mapGetters('audiences', ['platform'])
  },
  methods: {
    importFromExcel() {
      this.toggleActive(1)
      document.querySelector('#excel-input').click()
    },
    async excelFileChange(e) {
      this.$vs.loading()
      const files = this.$refs.excelFile.files
      e.dataTransfer = { files }
      const result = await xlsx.handleDrop(e)

      if (result && result.tickets) {
        if (files[0].name) {
          this.newName = files[0].name.split('.')[0]
        }
        this.newValues = result.tickets
          .filter(
            // name is optional
            element => element.number
          )
          // .map(element => ({
          //   fullname: `${element.name || ''}`.trim(),
          //   senderId: element.number.toString()
          //   Object.keys(element).forEach(key => {
          //   // const newKey = Helper.replaceSpecialCharacters(key)
          //   replacedElems[key] = element[key]
          // })
          .map(element => {
            let replacedElems = {}
            Object.keys(element).forEach(key => {
              // const newKey = Helper.replaceSpecialCharacters(key)
              if (key == 'name') {
                replacedElems['fullname'] = element[key]
              } else if (key == 'number') {
                replacedElems.senderId = element.number.toString()
              } else {
                if (!replacedElems.extra) {
                  replacedElems.extra = {}
                }
                replacedElems.extra[key] = element[key]
              }
            })

            return {
              ...replacedElems
            }
          })
        this.selected = this.newValues
      }
      const data = { values: this.newValues, selected: this.selected }
      this.$emit('selectedExcel', data)
      document.getElementById('excel-input').value = ''
      this.$vs.loading.close()
    },
    importFromBot() {
      this.toggleActive(2)
      this.showBotSelector = true
    },
    selectBot(bot) {
      this.$emit('selectedBot', bot)
      this.cancel()
      // if (!bot.legacy) {
      // let botName = bot.service.name
      // let botId = bot.id
      // let serviceId = bot.service.id
      // this.SET_BOT(bot)
      // }
    },
    cancel() {
      this.showBotSelector = false
    },
    toggleActive(active) {
      this.active = active
    },
    downloadExcelTemplate() {
      window.open(`audiences_template_${this.platform}.xlsx`, '_blank')
    }
  }
}
</script>

<style lang="scss">
.audience-selector {
  .import-selector {
    display: flex;
    flex-direction: row;

    &__option {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      width: 256px;
      height: 144px;
      background: white;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
      border-radius: 7px;
      padding: 20px;

      &:not(:first-of-type) {
        margin-left: 20px;
      }

      &.con-vs-card {
        min-height: 164px;
      }

      // &.active {
      &:hover {
        background: rgba(var(--vs-primary), 1) !important;
        .body .feather,
        .text {
          user-select: none;
          color: white;
        }
      }

      .body {
        display: flex;
        flex-direction: row-reverse;
        flex-grow: 1;
        .feather {
          font-size: 24px;
        }
      }
      .text {
        &__big {
          font-weight: bold;
          font-size: 1.5rem;
        }
      }

      #excel-input {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 1440px) {
  .audience-selector {
    .import-selector__option {
      width: 208px;
    }
  }
}
</style>
